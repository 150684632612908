import { UserDataType, UserRoles, auth, pendingAuthState } from '../../firebaseApp';

export const userHasAccessToPage = async (visibleOnlyToRoles: UserRoles[], userData: UserDataType): Promise<boolean> => {
  if (!visibleOnlyToRoles || visibleOnlyToRoles?.length === 0
      || userData?.roles?.includes(UserRoles.TeamMember)) {
    return true;
  } else if (auth.currentUser === null && visibleOnlyToRoles?.length > 0) {
    return false;
  }
  // check if the user's roles matches any of the visibleOnlyToRoles
  return visibleOnlyToRoles?.some((role) => userData?.roles?.includes(role));
};

export const isUserDataStillHydrating = (userPreviouslyLoggedIn: boolean,
                                         userData: UserDataType | null) => {
  return userPreviouslyLoggedIn && (pendingAuthState || auth.currentUser !== null) && userData === null;
};

const isMobile = () => {
  return navigator.userAgent.toLowerCase().includes('iphone')
    || navigator.userAgent.toLowerCase().includes('android')
    // @ts-expect-error userAgentData is not in the types yet (still experimental)
    || navigator.userAgentData?.platform.toLowerCase().includes('iphone')
    // @ts-expect-error userAgentData is not in the types yet (still experimental)
    || navigator.userAgentData?.platform.toLowerCase().includes('android');
};

export const isWindowsOs = () => {
  return navigator.userAgent.toLowerCase().includes('win64')
    // @ts-expect-error userAgentData is not in the types yet (still experimental)
    || navigator.userAgentData?.platform?.toLowerCase().includes('win')
    || navigator.platform?.toLowerCase().includes('win');
};

const isMacOs = () => {
  return navigator.userAgent.toLowerCase().includes('mac')
    // @ts-expect-error userAgentData is not in the types yet (still experimental)
    || navigator.userAgentData?.platform?.toLowerCase().includes('mac')
    || navigator.platform?.toLowerCase().includes('mac');
};

export const isSupportedOs = () => {
  return !isMobile() && (isWindowsOs() || isMacOs());
};

export function insertBetweenItems(arr, thing) {
  return [].concat(...arr.map((n) => [ n, thing ])).slice(0, -1);
}

export function createMeasureElement(text, insertElement) {
  const textWithoutBreaks = text
    .split('\n')
    .join('')
    .split('\r')
    .join('')
    .split(String.fromCharCode(10))
    .join('');

  const wordArray = textWithoutBreaks.split(' ');

  const alternatingArray = insertBetweenItems(wordArray, insertElement);

  return alternatingArray.join('');
}

export const isEmailAddress = (email: string): boolean => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

export const parseName = (name: string): { firstName: string, lastName: string } => {
  const firstName = name.split(/\s+/)[0] || '';
  const lastName = name.split(/\s+/).slice(1).join(' ') || '';
  return { firstName, lastName };
};
