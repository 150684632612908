import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
      textDecoration: 'none',
    },
    h1: {
      fontSize: '4.5rem',
      fontWeight: 800,
      lineHeight: '1.16',
      letterSpacing: '-1.5px',
      marginTop: '0',
      marginBottom: '1rem',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '1.2',
      letterSpacing: '-0.5px',
      marginTop: '0',
    },
    h3: {
      fontSize: '2.125rem',
      fontWeight: 500,
      lineHeight: '1.16',
      letterSpacing: '0',
      marginTop: '0',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.23',
      letterSpacing: '0.25px',
      marginTop: '0',
      marginBlockEnd: '0.5rem',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.4',
      letterSpacing: '0',
      marginTop: '0',
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.4',
      letterSpacing: '0.15px',
      marginTop: '0',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.17px',
      margin: '0',
      paddingBottom: '1rem',
    },
    button: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.2',
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
  },
});
