import React from 'react';
import './footer.scss';
import { navigationData } from '../navigation-menu/navigation-data';

const block = 'site-footer';

const Footer: React.FC = () => {
  return (
    <footer className={block}>
      <div className={`${block}__copywrite`}>
        © 2023 Amplio Music, All Rights Reserved.
      </div>
      <div className={`${block}__links`}>
        {
          navigationData.secondary.entries.map((link, index) => {
            if (link.isVisibleWhenLoggedOut) {
              return (
                <a className={`${block}__link`} key={index} href={link.url}>
                  {link.title}
                </a>
              );
            }
            return <></>;
          })
        }
      </div>
    </footer>
  );
};

export default Footer;
