import React, { useEffect } from 'react';
import { auth } from '../../firebaseApp';
import { User } from '@firebase/auth';

export function useUser() {
  const [ user, setUser ] = React.useState<User>(null);

  useEffect(() => {
    const unsubscribeFromAuthStateChanges = auth.onAuthStateChanged(async (updatedUser) => {
      setUser(updatedUser);
    });

    return () => {
      unsubscribeFromAuthStateChanges();
    };
  }, []);

  return { user };
}
