interface ShowProps {
  when: boolean
  children: React.ReactNode
}

export default function Show({ when, children }: ShowProps) {
  if (!when) return null;

  return children;
}
