import { Divider, IconButton, Skeleton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { UserRoles, signOutUser } from '../../../firebaseApp';
import { useUser } from '../../helpers/firebase-user-hook';
import { useStore } from '../../store/store';
import Show from '../show/show';
import './navigation-menu.scss';
import { LinkType } from '../../helpers/typings';
import { navigationData } from './navigation-data';

const block = 'navigation-menu';

interface NavigationMenuProps {
  showAllNavLinks?: boolean;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ showAllNavLinks = true }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const [ userAccountAnchor, setUserAccountAnchor ] = React.useState<null | HTMLElement>(null);
  const [
    userPreviouslyLoggedIn,
    userData,
    changeStoreState,
  ] = useStore((state) => [
    state.userPreviouslyLoggedIn,
    state.userData,
    state.changeStoreState,
  ]);
  const handleBurgerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => setAnchorEl(event.currentTarget);

  const [ avatarLoaded, setAvatarLoaded ] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setUserAccountAnchor(null);
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, url: string) => {
    if (url === '/login') {
      event.stopPropagation();
      event.preventDefault();
      changeStoreState('showLoginPopup', true);
      return;
    } else if (url === '/download') {
      event.stopPropagation();
      event.preventDefault();
      changeStoreState('showDownloadPopup', true);
      return;
    } else if (url === '/sign-up') {
      event.stopPropagation();
      event.preventDefault();
      changeStoreState('showSignupPopup', true);
      return;
    }
    // If the link url is not a special case, then just let the <a> element
    // handle the event naturally
  };

  const { user } = useUser();

  // When the user account changes, start loading the avatar, and mark
  // when it has loaded
  useEffect(() => {
    if (user?.photoURL) {
      const image = new Image();
      image.src = user.photoURL;
      image.onload = () => setAvatarLoaded(true);
    }
  }, [ user ]);

  const shouldDisplayLink = useCallback((link: LinkType) => {
    if (!showAllNavLinks) {
      return false;
    }
    const isShowingLoggedInSkeletons = !user && userPreviouslyLoggedIn;
    const isLoggedIn = !!user;
    if (link.hiddenWhenLoggedIn && (isLoggedIn || (isShowingLoggedInSkeletons))) {
      return false;
    } else if (!link.isVisibleWhenLoggedOut && !isLoggedIn) {
      return false;
    }
    return true;
  }, [ user, userPreviouslyLoggedIn, showAllNavLinks ]);

  const shouldDisplaySkeleton = useCallback((link: LinkType) => {
    const isShowingLoggedInSkeletons = !user && userPreviouslyLoggedIn;
    if (!isShowingLoggedInSkeletons) {
      return false;
    } else if (!link.isVisibleWhenLoggedOut) {
      // Only show logged in skeletons for menu entries that are only visible
      // when logged in
      return !shouldDisplayLink(link) && true;
    }
    return false;
  }, [ user, userPreviouslyLoggedIn, showAllNavLinks ]);

  return (
    <div className={block}>
      <div className={`${block}__logo`}>
        <a href="/">
          <StaticImage placeholder="blurred" alt="Amplio Logo" src="../../assets/images/amplio-logo.png" />
        </a>
      </div>
      <div className={`${block}__links`}>
        {navigationData.primary.entries.map((link, index) => {
          const linkClassName = `${block}__link h5`;
          if (shouldDisplaySkeleton(link)) {
            return <Skeleton key={index} variant="text">
              {/* Infer the size of the skeleton from the text that would be used */}
              <span className={linkClassName}>{link.title}</span>
            </Skeleton>;
          } else if (!shouldDisplayLink(link)) {
            return null;
          }
          return (
            <a
              className={linkClassName}
              key={index}
              onClick={(event) => handleLinkClick(event, link.url)}
              href={link.url}
            >
              {link.title}
            </a>
          );
        })}
      </div>
      <Show when={user === null && !userPreviouslyLoggedIn && showAllNavLinks}>
        <button className={`${block}__sign-up-button`} onClick={(event) => handleLinkClick(event, '/sign-up')}>
          Sign Up
        </button>
      </Show>
      <Show when={!user && userPreviouslyLoggedIn}>
        <Skeleton variant='circular' width={48} height={48} sx={{ marginLeft: '40px' }} />
      </Show>
      <Show when={user !== null}>
        <div className={`${block}__user-account-trigger hide-mobile`}>
          <IconButton onClick={(e) => setUserAccountAnchor(e.currentTarget)}>
            {
              user?.photoURL
                ? avatarLoaded
                  ? <img
                    width='48px'
                    src={user.photoURL}
                    alt='My profile image'
                    className={`${block}__user-avatar`}
                    referrerPolicy="no-referrer"
                  />
                  : <Skeleton
                    className={`${block}__user-avatar`}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                : <StaticImage
                  placeholder="blurred"
                  className={`${block}__user-avatar`}
                  alt="User Profile Image"
                  src="../../assets/images/default-avatar.png"
                />
            }
          </IconButton>
        </div>
      </Show>
      <Menu
        id="user-account-menu"
        anchorEl={userAccountAnchor}
        open={Boolean(userAccountAnchor)}
        onClose={() => setUserAccountAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={`${block}__user-profile-data`}>
          <div className={`${block}__user-meta`}>
            {
              user?.photoURL ?
                avatarLoaded
                  ? <img
                    width='120px'
                    src={user.photoURL}
                    alt='My profile image'
                    className={`${block}__user-avatar`}
                    referrerPolicy="no-referrer"
                  />
                  : <Skeleton
                    sx={{ margin: 'auto' }}
                    className={`${block}__user-avatar`}
                    variant="circular"
                    width={120}
                    height={120}
                  />
                : <StaticImage
                  placeholder="blurred"
                  className={`${block}__user-avatar`}
                  alt="User Profile Image"
                  src="../../assets/images/default-avatar.png"
                />
            }
            <Show when={user?.displayName && user?.displayName !== ''}>
              <h5 className={`${block}__user-initials`}>{user?.displayName}</h5>
            </Show>
          </div>
          <MenuItem className={`${block}__user-profile-menu-item`} onClick={() => handleClose()}>
            <a href="/manage-account">
              <h5>Manage Account</h5>
            </a>
          </MenuItem>
          <Show when={userData?.roles?.includes(UserRoles.DeepDiver)}>
            <MenuItem className={`${block}__user-profile-menu-item`} onClick={() => handleClose()}>
              <a href="/music-deep-dive-for-deaf-and-hard-of-hearing-people">
                <h5>Music Deep Dive</h5>
              </a>
            </MenuItem>
          </Show>
          <Show when={userData?.roles?.includes(UserRoles.TeamMember)}>
            <MenuItem className={`${block}__user-profile-menu-item`} onClick={() => handleClose()}>
              <a href="/backstage">
                <h5>Backstage</h5>
              </a>
            </MenuItem>
          </Show>
          <Divider />
          <MenuItem
            className={`${block}__user-profile-menu-item`}
            onClick={async () => {
              setUserAccountAnchor(null);
              await signOutUser();
            }}
          >
            <h5>Sign Out</h5>
          </MenuItem>
        </div>
      </Menu>
      {
        showAllNavLinks
        && <>
          <Menu
            id="navigation-burger-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Show when={user !== null}>
              <MenuItem
                className={`${block}__user-profile-menu-item`}
                sx={{ fontSize: '1.5rem' }}
                onClick={() => {
                  handleClose();
                }}
              >
                <a href='/manage-account'>
                  Manage Account
                </a>
              </MenuItem>
              <Show when={userData?.roles?.includes(UserRoles.TeamMember)}>
                <MenuItem
                  className={`${block}__user-profile-menu-item`}
                  onClick={() => handleClose()}
                  sx={{ fontSize: '1.5rem' }}
                >
                  <a href="/music-deep-dive-for-deaf-and-hard-of-hearing-people">
                    Music Deep Dive
                  </a>
                </MenuItem>
              </Show>
              <Show when={userData?.roles?.includes(UserRoles.DeepDiver)}>
                <MenuItem
                  className={`${block}__user-profile-menu-item`}
                  onClick={() => handleClose()}
                  sx={{ fontSize: '1.5rem' }}
                >
                  <a href="/backstage">
                    Backstage
                  </a>
                </MenuItem>
              </Show>
              <Divider />
            </Show>
            {
              navigationData.primary.entries.map((link, index) => {
                if (!shouldDisplayLink(link)) {
                  return [];
                }
                return (
                  <MenuItem
                    className={`${block}__user-profile-menu-item`}
                    onClick={handleClose}
                    key={index}
                    sx={{ fontSize: '1.5rem' }}
                  >
                    <a
                      key={index}
                      onClick={(event) => {
                        handleClose();
                        handleLinkClick(event, link.url);
                      }}
                      href={link.url}
                    >
                      {link.title}
                    </a>
                  </MenuItem>
                );
              })
            }
            <Show when={user === null}>
              <MenuItem
                sx={{ fontSize: '1.5rem' }}
                onClick={(event) => {
                  handleClose();
                  handleLinkClick(event, '/sign-up');
                }}
              >
                Sign Up
              </MenuItem>
            </Show>
            <Show when={user !== null}>
              <MenuItem
                sx={{ fontSize: '1.5rem' }}
                onClick={async () => {
                  await signOutUser();
                  handleClose();
                }}
              >
                Sign out
              </MenuItem>
            </Show>
          </Menu>
          <IconButton className={`${block}__burger-menu hide-desktop`} onClick={handleBurgerClick}>
            <MenuIcon size={34} />
          </IconButton>
        </>
      }
    </div>
  );
};
