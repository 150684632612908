import {
  Alert, Checkbox, CircularProgress, IconButton, InputAdornment, InputLabel,
  Modal, TextField, Typography
} from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { User } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Eye, EyeOff, X } from 'react-feather';
import {
  UserDataType, firestore, getAndCacheLatestUserData, logAnalyticsEvent, signUpUser, signUpUserWithGoogle
} from '../../../firebaseApp';
import { useStore } from '../../store/store';
import CountrySelect, { CountryType } from './countries';
import {
  GoogleIcon, addMarketingConsentInMailchimp, ensureUserIsInMailchimp,
  handleSongPlayClickRedirect,
  openAppWithAuthToken,
  validateEmail
} from './utils';

import './authentication-modal.scss';
import { useUser } from '../../helpers/firebase-user-hook';
import Show from '../show/show';

const block = 'authentication-modal';

interface SignUpModalProps {
  open: boolean
  onClose: VoidFunction
}

const SignUpModal: React.FC<SignUpModalProps> = ({ open, onClose }) => {
  const [
    isInProcessOfSigningUp,
    needsEmailVerification,
    userData,
    openAppAfterLogin,
    redirectAfterLogin,
    currentSong,
    changeStoreState,
  ] = useStore((state) => [
    state.isInProcessOfSigningUp,
    state.needsEmailVerification,
    state.userData,
    state.openAppAfterLogin,
    state.redirectAfterLogin,
    state.currentSong,
    state.changeStoreState,
  ]);
  const [ userCredential, setUserCredential ] = React.useState<User>(null);
  const [ userEmail, setUserEmail ] = React.useState('');
  const [ userPassword, setUserPassword ] = React.useState('');
  const [ userName, setUserName ] = React.useState('');
  const [ userCountry, setUserCountry ] = React.useState<CountryType>(null);
  const [ showPassword, setShowPassword ] = React.useState(false);
  const [ error, setError ] = React.useState('');
  const [ isConfirming, setIsConfirming ] = React.useState(false);
  const DEFAULT_MARKETING_CONSENT = true;
  const [ isMarketingEmailApproved, setIsMarketingEmailApproved ] =
    React.useState(DEFAULT_MARKETING_CONSENT);
  const [ signingUpWithSocial, setSigningUpWithSocial ] = React.useState(false);

  const { user } = useUser();

  if (user && !userCredential) {
    setUserCredential(user);
  }

  useEffect(() => {
    if (!user) {
      clearSettings();
    } else if (openAppAfterLogin) {
      // The user is already logged in and set up, so show the
      // "you are already logged in, continue?" dialog
      useStore.setState({
        showLoginPopup: true,
        showSignupPopup: false,
      });
    }
  }, [ user ]);

  useEffect(() => {
    if (userData) {
      if (userData.isAccountSetup && !openAppAfterLogin) {
        onClose();
      }
      setUserName(userData.name);
      setUserCountry({ label: userData.country } ?? null);
    }
  }, [ userData ]);

  const clearSettings = () => {
    changeStoreState('isInProcessOfSigningUp', false);
    setUserCredential(null);
    setUserEmail('');
    setUserPassword('');
    setUserName('');
    setIsMarketingEmailApproved(DEFAULT_MARKETING_CONSENT);
    setIsConfirming(false);
    setSigningUpWithSocial(false);
  };

  const confirmSignUp = async () => {
    setIsConfirming(true);
    let userId = userCredential?.uid;
    let currentUserEmail = userEmail;
    if (!userCredential) {
      // TODO handle errors here
      try {
        const user = await signUpUser(userEmail, userPassword);
        userId = user.uid;
      } catch (error) {
        if (error instanceof FirebaseError) {
          setError(error.code);
          console.log(error);
        }
        setIsConfirming(false);
        return;
      }
    } else {
      currentUserEmail = userCredential.email;
    }

    // TODO
    // check if user ID exists? throw error maybe? check if all fields are filled in
    // check if isAccountSetup !== true for this step
    // also set isAccountSetup = true if the above is successful
    const newUserData: UserDataType = {
      avatar: 'not set',
      country: userCountry.label,
      email: currentUserEmail,
      languages: [ 'English' ],
      name: userName,
      signLanguages: [],
      hasDownloadedApp: false,
      isMarketingEmailApproved,
      ...userData,
      // MUST come last to overwrite any previous value (i.e. false)
      isAccountSetup: true,
    };

    try {
      // TODO need proper error handling here
      await setDoc(doc(firestore, 'users', userId), newUserData);
    } catch (e) {
      console.error('error setting user data', e);
    }

    logAnalyticsEvent('signup');

    // TODO these really need to be one shot to add all segments, not
    // one at a time
    try{
      await ensureUserIsInMailchimp(currentUserEmail, userName, newUserData.country);
      if (isMarketingEmailApproved) {
        await addMarketingConsentInMailchimp(currentUserEmail);
      }
    } catch (e) {
      // TODO handle error here
    }

    if (openAppAfterLogin) {
      await openAppWithAuthToken();
    } else if (redirectAfterLogin) {
      handleSongPlayClickRedirect(redirectAfterLogin, currentSong);
      changeStoreState('redirectAfterLogin', null);
    }

    setIsConfirming(false);
    handleClose();
  };

  const logUserInGoogle = async () => {
    setSigningUpWithSocial(true);
    const user = await signUpUserWithGoogle();
    const tenMinutes = 10 * 60 * 1000;
    const tenMinutesAgo = Date.now() - (tenMinutes);
    // If the user was created mored than 10 minutes ago, then check for userData
    if (new Date(user.metadata.creationTime || Date.now()).getTime() < tenMinutesAgo) {
      try {
        await getAndCacheLatestUserData(user);
        return;
      } catch (e) {
        // pass
      }
    }
    setUserCredential(user);
    setSigningUpWithSocial(false);
    changeStoreState('isInProcessOfSigningUp', true);
  };

  const handleClose = () => {
    clearSettings();
    onClose();
  };

  const isFormEmpty = () => {
    if (isInProcessOfSigningUp) {
      if (userCredential === null) {
        if (
          userEmail === '' &&
          userPassword === '' &&
          userName === '' &&
          userCountry === null
        ) {
          return true;
        }
      } else {
        if (userName === '' && userCountry === null) {
          return true;
        }
      }
    }
    return false;
  };

  const isFormValid = () => {
    if (isInProcessOfSigningUp) {
      if (userCredential === null) {
        if (
          userEmail === '' ||
          userPassword === '' ||
          userName === '' ||
          userCountry === null
        ) {
          return false;
        }
      } else {
        if (userName === '' || userCountry === null) {
          return false;
        }
      }
    }
    return true;
  };

  const isPasswordError = () => {
    const wrongPassword = error === 'auth/wrong-password';
    const weakPassword = error === 'auth/weak-password';
    return wrongPassword || weakPassword;
  };

  const passwordErrorText = () => {
    if (error === 'auth/wrong-password') return 'Wrong user password';
    if (error === 'auth/weak-password') return 'Password should be at least 6 characters';
    return null;
  };

  const isEmailError = () => {
    const emailNotValid = !validateEmail(userEmail);

    return emailNotValid || error === 'auth/email-already-in-use';
  };

  const emailErrorText = () => {
    if (!validateEmail(userEmail)) return 'Please provide a valid user email';
    if (error === 'auth/email-already-in-use') return 'This email is already in use';
    return null;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={block}>
        <div className={`${block}__left-part`}>
          <div className={`${block}__text`}>
            <p>Amplio</p>
            <h2>Music</h2>
            <h3>For Deaf People</h3>
          </div>
          <StaticImage
            placeholder="blurred"
            className={`${block}__vector`}
            alt="Sound Wave Vector Blue"
            src="../../assets/svg/sound-wave-blue.svg"
          />
          <StaticImage
            placeholder="blurred"
            className={`${block}__photo`}
            alt="Photo of two people"
            src="../../assets/images/sign-in-photo.png"
          />
        </div>
        <div className={`${block}__right-part`}>
          <div className={`${block}__right-part-close-btn`} onClick={handleClose}>
            <IconButton>
              <X />
            </IconButton>
          </div>
          <div className={`${block}__right-part-content`}>
            <h2>Sign Up</h2>
            <h6>to see, hear, feel and customise music with Amplio 🎶</h6>
            <Show when={openAppAfterLogin}>
              <Alert severity='info' className={`${block}__open-app-after-login`}>
                After signing up, you will be redirected back to the app.
              </Alert>
            </Show>
            <Show when={signingUpWithSocial}>
              <div className={`${block}__login-in-progress-wrapper`}>
                <CircularProgress />
                <Typography variant="body1">
                  Connecting to account...
                </Typography>
              </div>
            </Show>
            { needsEmailVerification && <Alert severity="info">Please check your email to verify your account</Alert> }
            <Show when={!signingUpWithSocial && isInProcessOfSigningUp}>
              <div className={`${block}__form`}>
                {userCredential === null && (
                  <>
                    <div className={`${block}__input-wrap`}>
                      <InputLabel className={`${block}__input-label h6`}>Email</InputLabel>
                      <TextField
                        type="email"
                        placeholder="email"
                        value={userEmail}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                          if (error === 'auth/email-already-in-use') setError('');
                        }}
                        error={userEmail !== '' && isEmailError() && !isFormEmpty()}
                        helperText={userEmail !== '' && isEmailError() ? emailErrorText() : ''}
                      />
                    </div>
                    <div className={`${block}__input-wrap`}>
                      <InputLabel className={`${block}__input-label h6`}>Password</InputLabel>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        placeholder="password"
                        value={userPassword}
                        onChange={(e) => {
                          setUserPassword(e.target.value);
                          if (
                            error === 'auth/wrong-password' ||
                            error === 'auth/weak-password'
                          ) {
                            setError('');
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{ width: '28px', height: '28px' }}
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <EyeOff /> : <Eye />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          // disable autocomplete and autofill
                          autoComplete: 'new-password',
                        }}
                        error={isPasswordError() && !isFormEmpty()}
                        helperText={passwordErrorText()}
                      />
                    </div>
                  </>
                )}
                <div className={`${block}__input-wrap`}>
                  <InputLabel className={`${block}__input-label h6`}>Name</InputLabel>
                  <TextField
                    type="text"
                    placeholder="Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    // error={userName === '' && !isFormEmpty()}
                    // helperText={userName === '' ? 'Please provide your name' : null}
                  />
                </div>
                <div className={`${block}__input-wrap`}>
                  <InputLabel className={`${block}__input-label h6`}>Country</InputLabel>
                  <CountrySelect value={userCountry} onChange={(_, value) => setUserCountry(value)} />
                </div>
                <div className={`${block}__term`}>
                  <Checkbox
                    className={`${block}__term-checkbox`}
                    checked={true}
                    disabled={true}
                  />
                  <Typography variant="body1">
                    By signing up, I agree to receive email communication from
                    Amplio Music that is relevant to my account or contains
                    important information about the app or Amplio Music's
                    terms, policies, and agreements.
                  </Typography>
                </div>
                <div className={`${block}__term`}>
                  <Checkbox
                    className={`${block}__term-checkbox`}
                    checked={isMarketingEmailApproved}
                    onChange={(_, isChecked) => setIsMarketingEmailApproved(isChecked)}
                  />
                  <Typography variant="body1">
                    Yes, please! Keep me updated with new things that are
                    happening at Amplio Music, like new features that may
                    benefit me or new songs or genres I might like to explore!
                  </Typography>
                </div>

                <button onClick={confirmSignUp} disabled={!isFormValid()}>
                  { isConfirming ? <CircularProgress /> : 'Confirm' }
                </button>
              </div>
            </Show>
            <Show when={!isInProcessOfSigningUp && !signingUpWithSocial}>
              <button onClick={() => changeStoreState('isInProcessOfSigningUp', true)}>Sign up with email</button>
              <h6 className={`${block}__agreement-to-terms`}>
                By signing up, you agree to the{' '}
                <a target="_blank" href="/terms-and-conditions">
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>
              </h6>
              <div className={`${block}__oauth-button-wrap`}>
                <button className={`${block}__oauth-button`} onClick={logUserInGoogle}>
                  <GoogleIcon /> Sign up with Google
                </button>
              </div>
              <h6 className={`${block}__alternate-link`}>
                Already have an account?{' '}
                <a
                  href="#"
                  onClick={() => {
                    handleClose();
                    changeStoreState('showSignupPopup', false);
                    changeStoreState('showLoginPopup', true);
                  }}
                >
                  Sign in
                </a>
              </h6>
            </Show>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
