import Dialog from '@mui/material/Dialog/Dialog';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { X } from 'react-feather';
import { useStore } from '../../store/store';
import './download-app-popup.scss';
import { UserRoles, auth, logAnalyticsEvent, updateUserData } from '../../../firebaseApp';
import { handleSongPlayClickRedirect, markAppDownloadedInMailchimp, openApp } from '../authentication/utils';
import { isSupportedOs, isWindowsOs } from '../../helpers/general';
import Show from '../show/show';
import { navigate } from 'gatsby';
import ReusableDialog from './reusable-dialog';

const block = 'download-app-popup';
const windowsLink = 'https://github.com/ampliomusic/releases/releases/latest/download/AmplioInstaller.exe';
const macLink = 'https://github.com/ampliomusic/releases/releases/latest/download/AmplioInstaller.dmg';

export const DownloadAppPopup = () => {
  const [
    showDownloadPopup,
    userData,
    redirectAfterLogin,
    currentSong,
    changeStoreState,
  ] = useStore((state) => [
    state.showDownloadPopup,
    state.userData,
    state.redirectAfterLogin,
    state.currentSong,
    state.changeStoreState,
  ]);
  const setShowDownloadPopup = (value: boolean) => changeStoreState('showDownloadPopup', value);
  const [ suggestOpenApp, setSuggestOpenApp ] = React.useState(userData?.hasDownloadedApp || false);
  const [ downloadAnyway, setDownloadAnyway ] = React.useState(false);

  useEffect(() => {
    setSuggestOpenApp(userData?.hasDownloadedApp || false);
  }, [ userData ]);

  const onTermsAgree = async () => {
    if (auth.currentUser) {
      changeStoreState('showDownloadPopup', false);

      // Verify the user's account is set up, if not, show the account setup dialog
      if (!userData || !userData.isAccountSetup) {
        // The user needs to finish setting up their account
        changeStoreState('showNeedsAccountSetupDialog', true);
        return;
      }

      // Open the download link in a new window
      const link = isWindowsOs() ? windowsLink : macLink;
      window.open(link, '_blank');
      logAnalyticsEvent('download_app');

      // Mark that the user has downloaded the app in their account
      const newUserData = { ...userData };
      const firstTimeDownload = !userData?.hasDownloadedApp;

      newUserData.hasDownloadedApp = true;
      newUserData.agreedToDownloadTerms = true;
      await updateUserData(auth.currentUser, newUserData);
      // We don't want to subscribe developers or testers to the mailing list
      if (!userData.roles?.includes(UserRoles.Developer)
          && !userData.roles?.includes(UserRoles.Tester)
          && !userData.roles?.includes(UserRoles.TeamMember)) {
        await markAppDownloadedInMailchimp(userData.email);
      }

      // If this is the user's first time downloading the app, send them to the
      // get started page
      if (firstTimeDownload && !redirectAfterLogin) {
        navigate('/get-started/');
      } else if (redirectAfterLogin) {
        handleSongPlayClickRedirect(redirectAfterLogin, currentSong);
        changeStoreState('redirectAfterLogin', null);
      }
    } else {
      // If not logged in, show the login prompt
      changeStoreState('showLoginPopup', true);
      changeStoreState('showDownloadPopup', false);
      logAnalyticsEvent('attempted_app_download');
    }
  };

  const closeAndReset = () => {
    setShowDownloadPopup(false);
    setSuggestOpenApp(userData?.hasDownloadedApp || false);
  };

  const onDisagree = () => {
    // TODO do we need to update the user's information if they disagree?
    closeAndReset();
    logAnalyticsEvent('disagree_to_download_app');
  };

  const onCancelDialog = () => {
    closeAndReset();
    setDownloadAnyway(false);
  };

  const onOpenApp = async () => {
    closeAndReset();
    await openApp();
  };

  if (suggestOpenApp) {
    return (
      <ReusableDialog open={showDownloadPopup} onClose={onCancelDialog}>
        <h4 className={`${block}__dialog-title`}>Open the Amplio App!</h4>
        <h5 className={`${block}__dialog-subtitle`}>
            It looks like you might already have the Amplio software. Open it to play songs!
        </h5>
        <div className={`${block}__buttons-wrap`}>
          <button className={`${block}__agree-button secondary`} onClick={() => setSuggestOpenApp(false)}>
              Download Again
          </button>
          <button className={`${block}__agree-button`} onClick={onOpenApp}>
              Open Amplio
          </button>
        </div>
      </ReusableDialog>
    );
  }

  return (
    <ReusableDialog open={showDownloadPopup} onClose={onCancelDialog}>
      <Show when={!isSupportedOs() && !downloadAnyway}>
        <h4 className={`${block}__dialog-title`}>
            Looks like you are using an unsupported operating system.
        </h4>
        <h5 className={`${block}__dialog-subtitle`}>
            We are currently only supporting Windows and Mac OS. A mobile app is
            planned, but not yet available. It'll be big news when it happens,
            so we'll be sure to let you know when it's ready!
        </h5>
        <div className={`${block}__buttons-wrap`}>
          <button className='secondary' onClick={() => setDownloadAnyway(true)}>
              Download the desktop installer anyway
          </button>
          <button className='secondary' onClick={onCancelDialog}>
              Go Back
          </button>
        </div>
      </Show>
      <Show when={isSupportedOs() || downloadAnyway}>
        <h4 className={`${block}__dialog-title`}>Get the Desktop App!</h4>
        <h5 className={`${block}__dialog-subtitle`}>
            Download and install the desktop app to play Amplio songs.<br /><br />
            The Amplio app is both a music player and a research tool that helps
            us find out how Deaf and hard of hearing people access music. We will
            use what we learn to improve Amplio, to ensure artists get paid, and
            also to advise the wider music industry about how to make their music
            more inclusive.<br/><br/>
            In addition to agreeing to the
            site's <a href="/terms-and-conditions" target='_blank'>Terms and Conditions</a> and
            the <a href="/privacy-policy" target='_blank'>Privacy Policy</a>,
            you agree to:
        </h5>

        <div className={`${block}__terms`}>
          <div className={`${block}__term`}>
            <StaticImage
              placeholder="blurred"
              className={`${block}__term-icon`}
              alt="Term and condition icon"
              src="../../assets/svg/checkmark.svg"
            />
            <div className={`${block}__term-text`}>
              <h5 className={`${block}__term-title`}>
                <b>Give us permission to track which songs you play and their settings.</b>
              </h5>
            </div>
          </div>
          <div className={`${block}__term`}>
            <StaticImage
              placeholder="blurred"
              className={`${block}__term-icon`}
              alt="Term and condition icon"
              src="../../assets/svg/checkmark.svg"
            />
            <div className={`${block}__term-text`}>
              <h5 className={`${block}__term-title`}>
                <b>Give us permission to track which features you use.</b>
              </h5>
            </div>
          </div>
          <div className={`${block}__term`}>
            <StaticImage
              placeholder="blurred"
              className={`${block}__term-icon`}
              alt="Term and condition icon"
              src="../../assets/svg/checkmark.svg"
            />
            <div className={`${block}__term-text`}>
              <h5 className={`${block}__term-title`}>
                <b>Play songs for your personal use only</b>
              </h5>
              <h6 className={`${block}__term-subtitle`}>
                If you wish to play an Amplio song at a public event, you will
                need to obtain licences for the songs from your local copyright
                collection society. You will not need a license to display the app.
              </h6>
            </div>
          </div>
          <div className={`${block}__term`}>
            <StaticImage
              placeholder="blurred"
              className={`${block}__term-icon`}
              alt="Term and condition icon"
              src="../../assets/svg/checkmark.svg"
            />
            <div className={`${block}__term-text`}>
              <h5 className={`${block}__term-title`}>
                <b>Monitor the use of songs by children</b>
              </h5>
              <h6 className={`${block}__term-subtitle`}>
                It is the responsibility of parents and teachers to ensure the
                appropriateness of songs as some songs may contain mature content.
              </h6>
            </div>
          </div>
        </div>
        <div className={`${block}__buttons-wrap`}>
          <button className="secondary" onClick={onDisagree}>
              Disagree
          </button>
          <button className={`${block}__agree-button`} onClick={onTermsAgree}>
              Agree and Download
          </button>
        </div>
      </Show>
    </ReusableDialog>
  );
};
