import { Button, Dialog, DialogActions, DialogContent, DialogContentText, InputLabel, TextField } from '@mui/material';
import React from 'react';
import { useStore } from '../../store/store';
import { auth, resetPassword } from '../../../firebaseApp';

import './authentication-modal.scss';
import { validateEmail } from './utils';

const block = 'authentication-modal';

export const NeedsAccountSetupDialog: React.FC = () => {
  const [
    showNeedsAccountSetupDialog,
    changeStoreState,
  ] = useStore((state) => [
    state.showNeedsAccountSetupDialog,
    state.changeStoreState,
  ]);

  const handleContinue = () => {
    changeStoreState('showNeedsAccountSetupDialog', false);
    changeStoreState('isInProcessOfSigningUp', true);
    changeStoreState('showSignupPopup', true);
  };

  const handleSignOut = () => {
    changeStoreState('showNeedsAccountSetupDialog', false);
    auth.signOut();
  };

  return (
    <Dialog open={showNeedsAccountSetupDialog}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            You need to finish setting up your account. You may either finish
            setting up your account or sign back out.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSignOut}>Sign out</Button>
        <Button onClick={handleContinue} autoFocus>
            Continue Setup
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export const ForgotPasswordDialog: React.FC = () => {
  const [
    showPasswordResetDialog,
    changeStoreState,
  ] = useStore((state) => [
    state.showPasswordResetDialog,
    state.changeStoreState,
  ]);

  const [ userEmail, setUserEmail ] = React.useState('');

  const handleContinue = () => {
    resetPassword(userEmail);
    changeStoreState('showPasswordResetDialog', false);
  };

  const handleCancel = () => {
    changeStoreState('showPasswordResetDialog', false);
  };

  return (
    <Dialog className={`${block}__reset-dialog`} open={showPasswordResetDialog}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={`${block}__input-wrap`}>
            <InputLabel className={`${block}__input-label h6`}>Email</InputLabel>
            <TextField
              type="email"
              placeholder="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              error={!validateEmail(userEmail)}
              helperText={!validateEmail(userEmail) ? 'Please provide a valid user email' : null}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!validateEmail(userEmail)} onClick={handleContinue} autoFocus>
            Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};
