import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  User,
  updateEmail,
  updateProfile,
  signInWithPopup,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser,
  connectAuthEmulator,
} from 'firebase/auth';
import { DocumentReference, DocumentSnapshot, FieldValue, Timestamp, Unsubscribe, addDoc, collection, connectFirestoreEmulator, doc, getDoc, getFirestore, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { type Analytics, type EventNameString, getAnalytics, isSupported as isAnalyticsSupported, logEvent, setUserId } from 'firebase/analytics';
import { useStore } from './src/store/store';
import { firebaseConfig } from './firebaseConfig';

export let pendingAuthState = useStore.getState().userPreviouslyLoggedIn;

export enum UserRoles {
  Admin = 'admin',
  Developer = 'developer',
  TeamMember = 'teamMember',
  Musician = 'musician',
  DeepDiver = 'deepDiver',
  Tester = 'tester',
  SignedInUser = 'signedInUser',
}

export interface UserDataType {
  roles?: UserRoles[];
  roleGrantDate?: Record<UserRoles, Timestamp>;
  avatar: string;
  country: string;
  dob: Timestamp;
  email: string;
  languages: string[];
  name: string;
  signLanguages: string[];
  isAccountSetup: boolean;
  hasDownloadedApp?: boolean;
  agreedToDownloadTerms?: boolean;
  isMarketingEmailApproved?: boolean;
  isSignedInOnDesktop?: boolean;
  isEngagedUser?: boolean;
  formEmailAddresses?: string[];
  hasImmediateDeepDiveAccess?: boolean;
}

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
const cloudStorage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
let analytics: Analytics | null = null;
isAnalyticsSupported().then((isSupported) => {
  if (isSupported) {
    analytics = getAnalytics(firebaseApp);
  }
});

if (process.env.USE_EMULATOR === '1') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const googleProvider = new GoogleAuthProvider();

export const loginUser = async (loginEmail: string, loginPassword: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
  await getAndCacheLatestUserData(userCredential.user);
  checkUserSetup();
  return userCredential.user;
};

export const reAuthenticateUserWithPassword = async (loginPassword: string) => {
  await reauthenticateWithCredential(auth.currentUser, EmailAuthProvider.credential(auth.currentUser.email, loginPassword));
  useStore.setState({ needsReauthentication: false });
};

export const loginUserWithGoogle = async () => {
  const userCredential = await signInWithPopup(auth, googleProvider);
  await getAndCacheLatestUserData(userCredential.user);
  checkUserSetup();
  return userCredential.user;
};

export const signUpUserWithGoogle = async () => {
  const userCredential = await signInWithPopup(auth, googleProvider);
  return userCredential.user;
};

export const signUpUser = async (loginEmail: string, loginPassword: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, loginEmail, loginPassword);
  return userCredential.user;
};

export const updateUserEmail = async (user: User, newEmail: string) => {
  try {
    await updateEmail(user, newEmail);
  } catch (error) {
    if (error.code === 'auth/requires-recent-login') {
      useStore.setState({
        needsReauthentication: true,
        reasonToLogIn: 'It has been too long since you last signed in. '
          + 'Please sign in with your old email address to make updates your email address',
        showLoginPopup: true,
      });
    }
    throw error;
  }
  await verifyEmail(user);
};

export const updateUserPhotoUrl = async (photoURL: string): Promise<void> => {
  const user = auth.currentUser;
  if (user) {
    return updateProfile(user, {
      photoURL,
    });
  }
  throw new Error('No user signed in');
};

export const updateUserPassword = async (user: User, newPassword: string) => {
  await updatePassword(user, newPassword);
};

export const updateUserData = async (user: User, userData: UserDataType) => {
  if (!user) {
    return;
  }
  const docRef = doc(firestore, 'users', user.uid);
  await setDoc(docRef, userData);
  useStore.setState({ userData });
};

export const getAndCacheLatestUserData = async (user: User): Promise<UserDataType | null> => {
  let docRef;
  try {
    docRef = doc(firestore, 'users', user.uid);
  } catch (e) {
    console.error('got an error trying to get the user doc ref', e);
    return null;
  }
  const docSnap = await getDoc(docRef) as DocumentSnapshot<UserDataType>;
  const userDataExists = docSnap.exists();
  const userData = docSnap.data();
  if (userDataExists) {
    // Ensure the email on file matches the email used to sign up
    if (userData.email !== user.email) {
      userData.email = user.email;
      await updateUserData(user, userData);
    }
    useStore.setState({ userData });
    return userData;
  }
  return null;
};

export const resetPassword = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

export const verifyEmail = async (user: User, continueUrl?: string) => {
  try {
    await sendEmailVerification(user, { url: continueUrl });
  } catch (error) {
    if (error.code === 'auth/too-many-requests') {
      console.error('You have tried to send too many verification emails. Please try again later');
    }
  }
};

export const signOutUser = async () => {
  useStore.setState({ userData: null });
  await auth.signOut();
};

export const deleteUserAccount = async () => {
  try {
    await deleteUser(auth.currentUser);
  } catch (error) {
    if (error.code === 'auth/requires-recent-login') {
      useStore.setState({
        needsReauthentication: true,
        reasonToLogIn: 'It has been too long since you last signed in. '
          + 'Please sign in again before deleting your account',
        showLoginPopup: true,
      });
    }
    throw error;
  }
};

export const logAnalyticsEvent = (eventName: string | EventNameString,
                                  eventParams?: Record<string, unknown>): void => {
  if (analytics && !useStore.getState().userData?.roles?.includes(UserRoles.TeamMember)) {
    logEvent(analytics, eventName, eventParams);
  } else {
    console.log('Analytics not supported, logging event', eventName, eventParams);
  }
};

// Cloud Storage definitions
export const uploadFileForUser = async (file: File, path: string) => {
  const userFolderRef = ref(cloudStorage, `user-uploads/${auth.currentUser.uid}`);
  const fileRef = ref(userFolderRef, path);
  await uploadBytes(fileRef, file);
};

export const getPublicUrlForUserFile = async (path: string) => {
  const userFolderRef = ref(cloudStorage, `user-uploads/${auth.currentUser.uid}`);
  const fileRef = ref(userFolderRef, path);
  return getDownloadURL(fileRef);
};

export interface AnswerMapEntry {
  question: Queries.ContentfulFormQuestion;
  answer?: string | string[] | null;
  index: number;
}

interface FormSubmission {
  userId: string;
  nameOfUser: string;
  userEmail: string;
  timestamp: FieldValue;
  answers: AnswerMapEntry[];
  sendTo: string[];
  isPaymentForm: boolean;
  approvalQueueId?: string;
}

interface ContactDetails {
  email: string;
  first_name?: string;
  last_name?: string;
  country?: string;
  alternate_emails?: string[];
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state_province_region?: string;
  postal_code?: string;
  custom_fields?: {
    [key: string]: string;
    'deepDiver'?: 'true' | 'false';
    'hasAmplioAccount'?: 'true' | 'false';
    'hasDownloadedApp'?: 'true' | 'false';
    'isAccountSetup'?: 'true' | 'false';
    'isEngagedUser'?: 'true' | 'false';
    'isMarketingEmailApproved'?: 'true' | 'false';
    'addedFromLeadMagnet'?: string;
  };
}

interface UpsertContactsData {
  contacts: ContactDetails[];
  list_ids: string[];
}

interface SendLeadMagnetEmailData {
  name: string;
  email: string;
  leadMagnetName: string;
  emailTemplateId: string;
  downloadUrl: string;
}

// Cloud function definitions
export const mintCustomToken =
  httpsCallable<{idToken: string}, {token: string}>(functions, 'mintCustomToken');
export const upsertContacts =
  httpsCallable<UpsertContactsData, unknown>(functions, 'upsertContacts');
export const sendLeadMagnetEmail =
  httpsCallable<SendLeadMagnetEmailData, unknown>(functions, 'sendLeadMagnetEmail');

export const addFormAnswers = async (formId: string,
                                     answers: AnswerMapEntry[],
                                     sendToTheseEmails: string[],
                                     isPaymentForm=false,
                                     useEmail: string,
                                     useName?: string): Promise<string | null> => {
  const { userData } = useStore.getState();
  const nameOfUser = useName || userData?.name || auth?.currentUser?.displayName || 'Unknown Name';
  try {
    const formSubmissionSubCollection = collection(firestore, 'forms', formId, 'submissions');
    const formSubmissionData: FormSubmission = {
      userId: auth?.currentUser?.uid || 'unknown',
      nameOfUser,
      userEmail: useEmail,
      timestamp: serverTimestamp(),
      answers,
      sendTo: sendToTheseEmails,
      isPaymentForm,
    };
    const formSubmissionRef = await addDoc(formSubmissionSubCollection, formSubmissionData);
    console.log('Successfully sent form');
    return formSubmissionRef.id;
  } catch (e) {
    console.error('Error saving document', e);
    return null;
  }
};

function checkReasonToLogin() {
  // Show the download popup if the user tried to download before signin
  const { reasonToLogIn, needsReauthentication } = useStore.getState();
  if (reasonToLogIn?.includes('download')) {
    useStore.setState({ reasonToLogIn: null, showDownloadPopup: true });
  } else if (reasonToLogIn && !needsReauthentication) {
    useStore.setState({ reasonToLogIn: null });
  }
}

function checkEmailIsVerified(user: User) {
  if (!user.emailVerified) {
    // TODO - this should be a popup allowing the user to resend the
    // verification email to their email address
    useStore.setState({ needsEmailVerification: true });
  } else {
    useStore.setState({ needsEmailVerification: false });
  }
}

async function checkUserSetup() {
  // Check that their account still needs setup
  const userData = useStore.getState().userData;
  if ((!userData || !userData.isAccountSetup)
      && (!useStore.getState().isInProcessOfSigningUp)) {
    // The user needs to finish setting up their account
    useStore.setState({ showNeedsAccountSetupDialog: true });
    return;
  }
}

// Check on the user's account when their auth state changes
let unsubscribeFromUserDataUpdates: Unsubscribe | null = null;

auth.onAuthStateChanged(async (updatedUser) => {
  pendingAuthState = false;

  // Make sure we only have one listener hanging around, so clean this up first
  // if it already exists
  if (unsubscribeFromUserDataUpdates) {
    unsubscribeFromUserDataUpdates();
    unsubscribeFromUserDataUpdates = null;
  }

  if (updatedUser) {
    // Any time the user's data updates, we want to make sure the updated copy
    // is saved in the store
    let docRef: DocumentReference<UserDataType>;
    try {
      docRef = doc(firestore, 'users', updatedUser.uid) as DocumentReference<UserDataType>;
      unsubscribeFromUserDataUpdates = onSnapshot(docRef, (doc) => {
        const userData = doc.data();
        // Ensure we freeze the object coming from firebase so that it can't be
        // modified by the client
        useStore.setState({ userData: Object.freeze(userData) });
        checkUserSetup();
      });
    } catch (e) {
      console.error('Could not retrieve any user data', e);
    }
    checkEmailIsVerified(updatedUser);

    if (analytics) {
      setUserId(analytics, updatedUser.uid);
    }

    // Check if we need to redirect anywhere after checking this
    checkReasonToLogin();

    useStore.setState({ userPreviouslyLoggedIn: true, needsReauthentication: false });
  } else {
    useStore.setState({
      userData: null,
      userPreviouslyLoggedIn: false,
      needsReauthentication: false,
      reasonToLogIn: null,
      needsEmailVerification: false,
    });
  }
});
