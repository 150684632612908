import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserDataType } from '../../firebaseApp';
import { SongType } from '../helpers/typings';

export type ValueOf<T> = T[keyof T]

export interface SiteState {
  userData: UserDataType | null;
  userPreviouslyLoggedIn: boolean;
  showDownloadPopup: boolean;
  showLoginPopup: boolean;
  showSignupPopup: boolean;
  showNeedsAccountSetupDialog: boolean;
  isInProcessOfSigningUp: boolean;
  showPasswordResetDialog: boolean;
  needsEmailVerification: boolean;
  reasonToLogIn: string | null;
  openAppAfterLogin: boolean;
  redirectAfterLogin: string | null;
  needsReauthentication: boolean;
  currentSong: SongType | null;
  globalSearch: string | null;
  globalSearchHasResults: boolean;
  changeStoreState: (propertyName: keyof SiteState, propertyValue: ValueOf<SiteState>) => void
}

const excludeFromPersistence: (keyof SiteState)[] = [
  'userData',
  'showDownloadPopup',
  'showLoginPopup',
  'showSignupPopup',
  'showNeedsAccountSetupDialog',
  'isInProcessOfSigningUp',
  'showPasswordResetDialog',
  'needsEmailVerification',
  'reasonToLogIn',
  'openAppAfterLogin',
  'redirectAfterLogin',
  'needsReauthentication',
  'currentSong',
  'globalSearch',
  'globalSearchHasResults',
];

export type SetFuncType = (
  partial: SiteState | Partial<SiteState> | ((state: SiteState) => SiteState | Partial<SiteState>),
  replace?: boolean
) => void

export const initialState: (set: SetFuncType) => SiteState = (set) => {
  return {
    userData: null,
    userPreviouslyLoggedIn: false,
    showDownloadPopup: false,
    showLoginPopup: false,
    showSignupPopup: false,
    showNeedsAccountSetupDialog: false,
    isInProcessOfSigningUp: false,
    showPasswordResetDialog: false,
    needsEmailVerification: false,
    reasonToLogIn: null,
    openAppAfterLogin: false,
    redirectAfterLogin: null,
    needsReauthentication: false,
    currentSong: null,
    globalSearch: null,
    globalSearchHasResults: true,
    changeStoreState: (propertyName: keyof SiteState, propertyValue: ValueOf<SiteState>) =>
      set(() => ({ [propertyName]: propertyValue })),
  };
};

export const useStore = create<SiteState>()(
  persist((set) => initialState(set), {
    name: 'app-storage', // name of the item in the storage (must be unique)
    partialize: (state) =>
      Object.fromEntries(
        Object.entries(state).filter(([ key ]) => !excludeFromPersistence.includes(key as keyof SiteState))
      ),
  })
);
