import React from 'react';
import { auth, logAnalyticsEvent, mintCustomToken, upsertContacts } from '../../../firebaseApp';
import { navigate } from 'gatsby';
import { useStore } from '../../store/store';
import { SongType } from '../../helpers/typings';

export function validateEmail(value) {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex)) return true;
  return false;
}

export const GoogleIcon: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0005 12.7326C23.0005 11.8693 22.9291 11.2393 22.7744 10.5859H12.7148V14.4826H18.6196C18.5006 15.4509 17.8577 16.9093 16.4291 17.8892L16.4091 18.0197L19.5897 20.4344L19.8101 20.4559C21.8338 18.6242 23.0005 15.9292 23.0005 12.7326Z"
      fill="#4285F4"
    />
    <path
      d="M12.714 22.9996C15.6068 22.9996 18.0353 22.0662 19.8092 20.4562L16.4282 17.8895C15.5235 18.5078 14.3092 18.9395 12.714 18.9395C9.88069 18.9395 7.47596 17.1079 6.61874 14.5762L6.49309 14.5866L3.18583 17.095L3.14258 17.2128C4.90446 20.6428 8.5235 22.9996 12.714 22.9996Z"
      fill="#34A853"
    />
    <path
      d="M6.62095 14.5775C6.39476 13.9241 6.26386 13.2241 6.26386 12.5008C6.26386 11.7774 6.39476 11.0774 6.60905 10.4241L6.60306 10.285L3.25435 7.73633L3.14478 7.7874C2.41862 9.21075 2.00195 10.8091 2.00195 12.5008C2.00195 14.1924 2.41862 15.7907 3.14478 17.2141L6.62095 14.5775Z"
      fill="#FBBC05"
    />
    <path
      d="M12.7141 6.05997C14.7259 6.05997 16.083 6.91163 16.8569 7.62335L19.8807 4.73C18.0236 3.03834 15.6069 2 12.7141 2C8.52353 2 4.90447 4.35665 3.14258 7.78662L6.60686 10.4233C7.47598 7.89166 9.88073 6.05997 12.7141 6.05997Z"
      fill="#EB4335"
    />
  </svg>
);

export const FacebookIcon: React.FC = () => (<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="16" cy="16.5" r="14" fill="#0C82EE"/>
  <path d="M21.2137 20.7816L21.8356 16.8301H17.9452V14.267C17.9452 13.1857 18.4877 12.1311 20.2302 12.1311H22V8.76699C22 8.76699 20.3945 8.5 18.8603 8.5C15.6548 8.5 13.5617 10.3929 13.5617 13.8184V16.8301H10V20.7816H13.5617V30.3345C14.2767 30.444 15.0082 30.5 15.7534 30.5C16.4986 30.5 17.2302 30.444 17.9452 30.3345V20.7816H21.2137Z" fill="white"/>
</svg>);

export const ensureUserIsInMailchimp = async (email: string, name: string, country: string) => {
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ').slice(1).join(' ');
  try {
    await upsertContacts({
      list_ids: [],
      contacts: [
        {
          email,
          first_name: firstName,
          last_name: lastName,
          country,
        },
      ],
    });
  } catch (e) {
    logAnalyticsEvent('subscribe-response-error', { error: e });
    return;
  }
};

export const markAppDownloadedInMailchimp = async (email: string) => {
  try {
    await upsertContacts({
      list_ids: [],
      contacts: [
        {
          email,
          custom_fields: {
            hasDownloadedApp: 'true',
          },
        },
      ],
    });
  } catch (e) {
    logAnalyticsEvent('markAppDownloadedInMailchimp-response-error', { error: e });
    return;
  }
};

export const addMarketingConsentInMailchimp = async (email: string) => {
  try {
    await upsertContacts({
      list_ids: [],
      contacts: [
        {
          email,
          custom_fields: {
            isMarketingEmailApproved: 'true',
          },
        },
      ],
    });
  } catch (e) {
    logAnalyticsEvent('addMarketingConsentInMailchimp-response-error', { error: e });
    return;
  }

};

const OPEN_APP_URL = 'amplio://open/';

export async function openApp(url = OPEN_APP_URL) {
  await navigate(url);
  useStore.setState({ openAppAfterLogin: false });
}

export async function openAppWithAuthToken() {
  const idToken = await auth.currentUser?.getIdToken();
  const desktopAuth = await mintCustomToken({ idToken });
  await openApp(`${OPEN_APP_URL}?auth=${desktopAuth.data.token}`);
}

export function handleSongPlayClickRedirect(url: string, song: SongType) {
  window.location.href = url;
  logAnalyticsEvent('song_play_click', {
    songId: song.songId,
    songTitle: song.title,
    songArtist: song.artist,
    songVersion: song.songVersion,
    userId: auth.currentUser.uid,
  });
}
