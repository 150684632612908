export const navigationData = {
  'primary': {
    'entries': [
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'Home',
        'url': '/',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'About Amplio',
        'url': '/about-amplio',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'Explore Songs',
        'url': '/explore-songs',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'Contact Us',
        'url': 'mailto:amplio@rosegate.co',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'FAQs',
        'url': '/faqs',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': false,
        'title': 'Download App',
        'url': '/download',
      },
      {
        'hiddenWhenLoggedIn': true,
        'isVisibleWhenLoggedOut': true,
        'title': 'Sign In',
        'url': '/login',
      },
    ],
  },
  'secondary': {
    'entries': [
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'Terms & Conditions',
        'url': '/terms-and-conditions',
      },
      {
        'hiddenWhenLoggedIn': false,
        'isVisibleWhenLoggedOut': true,
        'title': 'Privacy Policy',
        'url': '/privacy-policy',
      },
    ],
  },
};
