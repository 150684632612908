import '@fontsource/dm-sans';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-mono';
import '@fontsource/dm-mono/500.css';

import React from 'react';
import Layout from './src/components/layout';
import { ThemeProvider } from '@mui/material';
import { theme } from './src/styles/theme';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};
