import { Alert, CircularProgress, IconButton, InputAdornment, InputLabel, Modal, TextField, Typography } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Eye, EyeOff, X } from 'react-feather';
import { loginUser, auth, logAnalyticsEvent, getAndCacheLatestUserData, loginUserWithGoogle, signOutUser, reAuthenticateUserWithPassword, UserRoles } from '../../../firebaseApp';
import { useStore } from '../../store/store';
import { GoogleIcon, addMarketingConsentInMailchimp, ensureUserIsInMailchimp, handleSongPlayClickRedirect, markAppDownloadedInMailchimp, openAppWithAuthToken, validateEmail } from './utils';

import './authentication-modal.scss';
import Show from '../show/show';
const block = 'authentication-modal';

interface SignInModalProps {
  open: boolean
  onClose: VoidFunction
}

const SignInModal: React.FC<SignInModalProps> = ({ open, onClose }) => {
  const [
    reasonToLogIn,
    openAppAfterLogin,
    userData,
    needsReauthentication,
    redirectAfterLogin,
    currentSong,
    changeStoreState,
  ] = useStore((state) => [
    state.reasonToLogIn,
    state.openAppAfterLogin,
    state.userData,
    state.needsReauthentication,
    state.redirectAfterLogin,
    state.currentSong,
    state.changeStoreState,
  ]);

  const [ userEmail, setUserEmail ] = React.useState('');
  const [ userPassword, setUserPassword ] = React.useState('');
  const [ showPassword, setShowPassword ] = React.useState(false);
  const [ errorCode, setErrorCode ] = React.useState('');

  const [ isLoggingIn, setIsLoggingIn ] = React.useState(false);

  const handleContinueAsUser = async () => {
    if (openAppAfterLogin) {
      await openAppWithAuthToken();
    } else if (redirectAfterLogin) {
      handleSongPlayClickRedirect(redirectAfterLogin, currentSong);
      changeStoreState('redirectAfterLogin', null);
    }
    onClose();
  };

  const logIn = async (loginType: 'password' | 'google') => {
    setIsLoggingIn(true);
    try {
      if (loginType === 'password') {
        if (needsReauthentication) {
          await reAuthenticateUserWithPassword(userPassword);
        } else {
          await loginUser(userEmail, userPassword);
        }
      } else if (loginType === 'google') {
        await loginUserWithGoogle();
      }
      logAnalyticsEvent('login');
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorCode(error.code);
        setIsLoggingIn(false);
        return;
      }
      console.error('Error signing in', error);
      logAnalyticsEvent('login_error', { error, loginType });
    }
    setErrorCode('');
    await handleContinueAsUser();
    setIsLoggingIn(false);

    // Ensure user is added to mailchimp, won't resubscribe unsubscribed users
    getAndCacheLatestUserData(auth.currentUser)
      .then(async (userData) => {
        // We don't want to subscribe developers or testers to the mailing list
        if (userData.roles?.includes(UserRoles.Developer)
            || userData.roles?.includes(UserRoles.Tester)
            || userData.roles?.includes(UserRoles.TeamMember)) {
          return;
        }
        await ensureUserIsInMailchimp(userData.email, userData.name, userData.country);
        if (userData.hasDownloadedApp) {
          await markAppDownloadedInMailchimp(userData.email);
        }
        if (userData.isMarketingEmailApproved) {
          await addMarketingConsentInMailchimp(userData.email);
        }
      })
      .catch((error) => {
        logAnalyticsEvent('get_user_data_error', {
          error,
          loginType,
          reason: 'Attempting to get user data after login',
        });
      });
  };

  const forgotPassword = async () => {
    changeStoreState('showPasswordResetDialog', true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={block}>
        <div className={`${block}__left-part`}>
          <div className={`${block}__text`}>
            <p>Amplio</p>
            <h2>Music</h2>
            <h3>For Deaf People</h3>
          </div>
          <StaticImage
            placeholder="blurred"
            className={`${block}__vector`}
            alt="Sound Wave Vector Blue"
            src="../../assets/svg/sound-wave-blue.svg"
          />
          <StaticImage
            placeholder="blurred"
            className={`${block}__photo`}
            alt="Photo of two people"
            src="../../assets/images/sign-in-photo.png"
          />
        </div>
        <div className={`${block}__right-part`}>
          <div className={`${block}__right-part-close-btn`} onClick={onClose}>
            <X />
          </div>
          <div className={`${block}__right-part-content`}>
            <h2>Sign In</h2>
            <h6>{reasonToLogIn ? reasonToLogIn : 'Welcome back 👋'}</h6>
            <Show when={openAppAfterLogin}>
              <Alert severity='info' className={`${block}__open-app-after-login`}>
                After signing in, you will be redirected back to the app.
              </Alert>
            </Show>

            <Show when={auth.currentUser !== null && !isLoggingIn && !needsReauthentication}>
              <div className={`${block}__already-logged-in`}>
                <h6>
                  You are already signed in as {auth.currentUser?.displayName || userData?.name || auth.currentUser?.email}
                </h6>
                <button
                  onClick={async () => {
                    setIsLoggingIn(true);
                    await handleContinueAsUser();
                    setIsLoggingIn(false);
                  }}
                >
                  Continue as {auth.currentUser?.displayName || userData?.name || auth.currentUser?.email}
                </button>
                <button onClick={() => signOutUser()}>Sign out</button>
              </div>
            </Show>
            <Show when={isLoggingIn}>
              <div className={`${block}__login-in-progress-wrapper`}>
                <CircularProgress />
                <Typography variant="body1">
                  Signing in...
                </Typography>
              </div>
            </Show>
            <Show when={!isLoggingIn && (auth.currentUser === null || needsReauthentication)}>
              <div className={`${block}__form`}>
                <div className={`${block}__input-wrap`}>
                  <InputLabel
                    className={`${block}__input-label h6`}
                    sx={ needsReauthentication ? { display: 'none' } : {}}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    type="email"
                    placeholder="email"
                    autoComplete='email username'
                    sx={ needsReauthentication ? { display: 'none' } : {}}
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    error={
                      (userEmail !== '' && !validateEmail(userEmail))
                          || (errorCode !== ''
                              && errorCode !== 'auth/wrong-password'
                              && errorCode !== 'auth/popup-closed-by-user')
                    }
                    helperText={userEmail !== '' && !validateEmail(userEmail) ? 'Please provide a valid user email' : null}
                  />
                </div>
                <div className={`${block}__input-wrap`}>
                  <InputLabel className={`${block}__input-label h6`}>Password</InputLabel>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    placeholder="password"
                    autoComplete='current-password'
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            style={{ width: '28px', height: '28px' }}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <EyeOff /> : <Eye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errorCode === 'auth/wrong-password'}
                    helperText={errorCode === 'auth/wrong-password' ? 'Wrong user password' : null}
                  />
                </div>
                <button onClick={() => logIn('password')}>Sign In</button>
                <div
                  className={`${block}__forgot-password`}
                  onClick={forgotPassword}
                >
                  Forgot your password?
                </div>
              </div>

              <div className={`${block}__oauth-button-wrap`}>
                <button
                  className={`${block}__oauth-button`}
                  onClick={() => logIn('google')}
                >
                  <GoogleIcon /> Sign In with Google
                </button>
              </div>
              <h6 className={`${block}__alternate-link`}>
                Don't have an account? <a href='#' onClick={() => { onClose(); changeStoreState('showSignupPopup', true); changeStoreState('showLoginPopup', false); }}>Sign up</a>
              </h6>
            </Show>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignInModal;
