import { Dialog } from '@mui/material';
import React from 'react';
import { X } from 'react-feather';

import './reusable-dialog.scss';

const block = 'reusable-dialog';
interface ReusableDialogProps {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const ReusableDialog: React.FC<ReusableDialogProps> = (props) => {
  return (
    <Dialog className={`${block}__dialog`} open={props.open} onClose={props.onClose}>
      <div className={`${block}__dialog-container`}>
        <X className={`${block}__close-dialog`} size={32} onClick={props.onClose} />
        {
          props.children
        }
      </div>
    </Dialog>
  );
};

export default ReusableDialog;
