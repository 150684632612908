import React from 'react';
import Footer from '../components/footer/footer';
import { DownloadAppPopup } from './modal/download-app-popup';
import { NavigationMenu } from './navigation-menu/navigation-menu';
import '../styles/global.scss';
import SignInModal from './authentication/sign-in-modal';
import SignUpModal from './authentication/sign-up-modal';
import { ForgotPasswordDialog, NeedsAccountSetupDialog } from './authentication/dialogs';
import { useStore } from '../store/store';
import { Script } from 'gatsby';

interface LayoutProps {
  children: React.ReactNode;
  data?: {
    contentfulPageTemplate: Queries.ContentfulPageTemplate;
  };
}

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  let showAllNavLinks = true;
  if (props.data?.contentfulPageTemplate?.showMinimalNavbar !== undefined) {
    showAllNavLinks = !props.data?.contentfulPageTemplate.showMinimalNavbar;
  }
  const [
    showLoginPopup,
    showSignupPopup,
    changeStoreState,
  ] = useStore((state) => [
    state.showLoginPopup,
    state.showSignupPopup,
    state.changeStoreState,
  ]);
  return (
    <div className="layout">
      <NavigationMenu showAllNavLinks={showAllNavLinks} />
      <div className="content">{props.children}</div>
      <DownloadAppPopup />
      <Footer />

      {/* Popups, dialogs and modals */}
      <SignInModal open={showLoginPopup} onClose={() => changeStoreState('showLoginPopup', false)} />
      <SignUpModal open={showSignupPopup} onClose={() => changeStoreState('showSignupPopup', false)} />
      <NeedsAccountSetupDialog />
      <ForgotPasswordDialog />

      {/* Scripts */}
      <Script
        defer
        data-domain="ampliomusic.com"
        src="https://plausible.io/js/script.outbound-links.tagged-events.js"
      />
    </div>
  );
};

export default Layout;
